<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-btn color="indigo darken-4" dark class="mb-2 ma-3 logi" @click="offer_new_company()">Schedule New Company</v-btn>
    <v-card style="padding: 10px">
      <v-card-title>Company Scheduling</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete outlined item-text="ay" item-value="id" :items="ay_list" v-model="selected_ay"
              :rules="[(v) => !!v || 'required']" label="Academic Year"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox @change="setvariable()" @input.native="setcompany_valiable($event.srcElement.value)" outlined
              :items="company_list" v-model="selected_company" label="Company" item-text="name" item-value="name"
              hide-no-data hide-details placeholder="Please Enter Name of Offered Company"></v-combobox>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete outlined item-text="name" item-value="id" :items="placement_type_list"
              v-model="selected_placement_type" :rules="[(v) => !!v || 'required']"
              label="Placement Type"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" md="2">
            <center>
              <v-btn color="indigo darken-4" dark class="mb-2 logi" v-on="on" @click="fetch_Company_Scheduling_new()" dense>
                Fetch</v-btn>
            </center>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-skeleton-loader class="ma-3 pa-2" v-if="loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
    <v-card v-else>
      <v-data-table hide-default-footer disable-pagination :headers="headers" group-by="companylevel"
        :items="schedule_company_list" class="elevation-1" :search="search">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Offered Company</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
            <span style="width: 40px"></span>
            <v-toolbar flat dense>
              <download-excel v-if="schedule_company_list.length > 0" class="mr-2 pb-3 pt-2"
                :data="schedule_company_list" :fields="table_fields" worksheet="Compnay_Scheduling_Info"
                name="Compnay_Scheduling_Info.xls">
                <v-btn color="success" outlined rounded><v-icon dark>mdi-file-excel</v-icon> Excel</v-btn>
              </download-excel>
            </v-toolbar>
          </v-toolbar>
        </template>
        <template v-slot:item.regstartdatetime="{ item }">
          {{ item.regStartdate }} {{ item.regStarttime }}</template>
        <template v-slot:item.regenddatetime="{ item }">
          {{ item.regEnddate }} {{ item.regEndtime }}
        </template>
        <template v-slot:item.isactive="{ item }">
          <v-switch v-if="item.editrightpresent" v-model="item.isactive" @change="activate(item.id)"
            :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
          <v-switch v-else v-model="item.isactive" disabled="true"
            :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
        </template>
        <template v-slot:item.action="{ item }">
          <div>
            <v-icon class="edit-v-icon mr-2" @click="geteditComapnyScheduling(item.id)">edit</v-icon>

            <v-icon v-if="item.question == 'available' || item.oncampus == 'available'"></v-icon>
            <v-icon v-else color="red" @click="deleteItem(item.id)"> mdi-delete</v-icon>
          </div>
        </template>
        <template v-slot:item.reschedule="{ item }">
          <v-btn color="primary" @click="reschedule(item)"> Reschedule</v-btn>
        </template>
        <template v-slot:item.campus="{ item }">
          <v-btn v-if="item.oncampus == 'available'" color="green" @click="companyoncampus(item)">
            On Campus</v-btn>
          <v-btn v-else color="red" @click="companyoncampus(item)"> On Campus</v-btn>
        </template>

        <template v-slot:item.addquestions="{ item }">
          <v-btn v-if="item.question == 'available'" color="green" @click="addquestions(item)">
            Questions</v-btn>
          <v-btn v-else color="red" @click="addquestions(item)"> Questions</v-btn>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon color="green" v-if="item.docUrl" @click="openfile(item.docUrl)">mdi mdi-eye</v-icon>
          <v-icon color="blue" @click="open_final_mail_attachment(item)">mdi mdi-plus</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog width="350" persistent v-model="dialog_attachment">
      <v-card>
        <v-card-title class="c-title" >Upload Final Mail </v-card-title>
        <v-card-text class="c-title">
          <v-file-input v-model="file"></v-file-input>
          <center> <v-btn color="primary darken-1" outlined text @click="dialog_attachment = false">Cancel</v-btn>
            <v-btn style="margin-left:60px"  :loading="final_mail_loading" color="primary darken-1" dark @click="savefinalmail()">Save</v-btn>
          </center>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="1500px">
      <v-skeleton-loader v-if="editloading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
      <v-card v-else>
        <v-form v-model="valid">
          <v-toolbar flat dense class="tc-title">
            <v-toolbar-title><span class="headline"> Edit Company Scheduling </span></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <span style="color: red; font-size: 12px; font-weight: 700; float: right">* indicates mandatory
              fields</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="dialog = false"><v-icon color="black">mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete item-text="name" item-value="id" :items="company_list" v-model="edit_selected_company"
                    :rules="[(v) => !!v || 'required']" label="Company*"
                    @change="getcode(edit_selected_company, edit_selected_ay)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="ay_list" item-text="ay" item-value="id" v-model="edit_selected_ay"
                    :rules="[(v) => !!v || 'required']" label="Academic Year*"
                    @change="getcode(edit_selected_company, edit_selected_ay)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field label="Company Code" disabled v-model="edit_selected_company_code">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="placement_type_list" item-text="name" item-value="id"
                    v-model="edit_selected_placement_type" @change="findinternshiptype()"
                    :rules="[(v) => !!v || 'required']" label="Company Offering*"></v-autocomplete>
                </v-col>
                <template v-if="display_internship">
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete :items="internship_type_list" item-text="name" item-value="id"
                      v-model="edit_selected_intership_type" label="Internship Duration"></v-autocomplete>
                  </v-col>
                </template>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="company_type_list" item-text="name" item-value="id"
                    v-model="edit_selected_company_type" :rules="[(v) => !!v || 'required']"
                    label="Company Type*"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="company_level_list" v-model="edit_selected_company_level"
                    :rules="[(v) => !!v || 'required']" label="Company Level*"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" v-if="edit_selected_company_level == 'Department Level'">
                  <v-autocomplete :items="department_list" v-model="edit_selected_department"
                    :rules="[(v) => !!v || 'required']" label="Department*"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="primary darken-1" @click="edit_company_scheduling()" :disabled="!valid">Save</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="offernew_dialog" max-width="1500px">
      <v-skeleton-loader v-if="offernewloading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
      <v-card v-else>
        <v-form v-model="valid">
          <v-toolbar flat dense class="tc-title">
            <v-toolbar-title><span class="headline">Company Scheduling </span></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <span style="color: red; font-size: 12px; font-weight: 700; float: right">* indicates mandatory
              fields</span>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="offernew_dialog = false"><v-icon color="black">mdi-close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete item-text="name" item-value="id" :items="company_list" v-model="new_selected_company"
                    :rules="[(v) => !!v || 'required']" label="Company*"
                    @change="getcode_new(new_selected_company, new_selected_ay)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="ay_list" item-text="ay" item-value="id" v-model="new_selected_ay"
                    :rules="[(v) => !!v || 'required']" label="Academic Year*"
                    @change="getcode_new(new_selected_company, new_selected_ay)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field label="Company Code" disabled v-model="new_selected_company_code">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="placement_type_list" item-text="name" item-value="id"
                    v-model="new_selected_placement_type" @change="findinternshiptype1()"
                    :rules="[(v) => !!v || 'required']" label="Company Offering*"></v-autocomplete>
                </v-col>
                <template v-if="new_display_internship">
                  <v-col cols="12" sm="3" md="3">
                    <v-autocomplete :items="internship_type_list" item-text="name" item-value="id"
                      v-model="new_selected_intership_type" label="Internship Duration"></v-autocomplete>
                  </v-col>
                </template>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="company_type_list" item-text="name" item-value="id"
                    v-model="new_selected_company_type" :rules="[(v) => !!v || 'required']"
                    label="Company Type*"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete :items="company_level_list" v-model="new_selected_company_level"
                    :rules="[(v) => !!v || 'required']" label="Company Level*"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" v-if="new_selected_company_level == 'Department Level'">
                  <v-autocomplete :items="department_list" v-model="new_selected_department"
                    :rules="[(v) => !!v || 'required']" label="Department*"></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close1">Cancel</v-btn>
              <v-btn color="primary darken-1" @click="save_new_company_scheduling()" :disabled="!valid">Save</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reschedule_dialog" max-width="1500px">

      <v-card>
        <v-card-title class="c-title">Reschedule</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field class="mt-4" v-model="reschedule_company" disabled dense label="Company*"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-autocomplete :items="ay_list" item-text="ay" item-value="id" v-model="reschedule_selected_ay"
                :rules="[(v) => !!v || 'required']" label="Academic Year*"
                @change="getcode_reschedule(reschedule_company_id, reschedule_selected_ay)"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field class="mt-4" v-model="reschedule_company_code" disabled dense
                label="CompanyOfferingCode*"></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">

              <v-btn @click="reschedulecompanyoffering()" dark color="blue">Copy</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      file: null,
      final_mail_loading:false,
      dialog_attachment: false,
      reschedule_dialog: false,
      valid: false,
      placement_type_list: [],
      company_list: [],
      ay_list: [],
      selected_placement_type: "",
      selected_company: "",
      selected_company_type: "",
      selected_ay: "",
      snackbar_msg: "",
      color: "",
      snackbar: false,
      schedule_company_list: [],
      loading: false,
      headers: [
        {
          text: "Company",
          value: "company",
        },
        {
          text: "Company Code",
          value: "company_code",
        },
        {
          text: "Registration start Date",
          value: "regstartdatetime",
        },
        {
          text: "Registration end Date",
          value: "regenddatetime",
        },
        {
          text: "Final Mail Form Company",
          value: "id",
        },
        // {
        //   text: "Status",
        //   value: "isactive",
        // },
        {
          text: "Actions",
          value: "action",
          sortable: false,
        },
        {
          text: "",
          value: "campus",
          sortable: false,
        },
        {
          text: "",
          value: "addquestions",
          sortable: false,
        },
        {
          text: "",
          value: "reschedule",
          sortable: false,
        },
      ],
      dialog: false,
      editloading: false,
      edit_selected_department: "",
      edit_selected_company_level: "",
      edit_selected_company_type: "",
      edit_selected_intership_type: "",
      edit_selected_placement_type: "",
      edit_selected_company_code: "",
      edit_selected_ay: "",
      edit_selected_company: "",
      edit_offer_comapny_id: "",
      department_list: [],
      company_level_list: [],
      company_type_list: [],
      internship_type_list: [],
      display_internship: false,
      search: "",

      offernewloading: false,
      offernew_dialog: false,
      new_selected_department: "",
      new_selected_company_level: "",
      new_selected_company_type: "",
      new_selected_intership_type: "",
      new_selected_placement_type: "",
      new_selected_company_code: "",
      new_selected_ay: "",
      new_selected_company: "",
      new_display_internship: false,
      reschedule_company: "",
      reschedule_company_id: "",
      reschedule_companyoffering_id: "",
      reschedule_selected_ay: "",
      reschedule_company_code: "",
      scheduling_id:"",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    openfile(url)
    {
      window.open(url,"_blank")
    },
    open_final_mail_attachment(id)
    {
      this.dialog_attachment=true;
      this.scheduling_id=id;
      this.file=null;
    },
    savefinalmail() {
      if (!this.file) {
        this.showSnackbar("#b71c1c", "Please Select A File...");
        return;
      }
      let formData = new FormData();
      formData.append("file",this.file);
      formData.append("company_name", this.companyname);
      formData.append("scheduling_id", this.scheduling_id.id);
      this.final_mail_loading = true;
      axios
        .post("/TPOCompanyScheduling/savefinalmail", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.msg == "200") {
            this.dialog_attachment = false;
            this.showSnackbar("#4caf50", "Attachment Added Successfully..."); // show snackbar on success
            this.final_mail_loading = false;
            this.scheduling_id.docUrl=res.data.docUrl;
          } else {
            this.dialog_attachment = false;
            this.showSnackbar("#b71c1c", res.data.msg);
            this.final_mail_loading = false;
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    reschedulecompanyoffering() {
      //console.log(this.rescheduleitem);
      var parmas = {
        prev_companyoffering_id: this.reschedule_companyoffering_id,
        company_id: this.reschedule_company_id,
        academicyear: this.reschedule_selected_ay,
        company_code: this.reschedule_company_code,

      }
      console.log(parmas);
      axios
        .post("/TPOCompanyScheduling/reschedulecompanyoffering", parmas)
        .then((res) => {
          //window.console.log(res);
          if (res.data.status.code == "SUCCESS") {
            this.reschedule_dialog = false;
            this.reschedule_company = "";
            this.reschedule_company_id = "";
            this.reschedule_companyoffering_id = "";

            this.showSnackbar(
              "#4caf50",
              "Offered Company Successfully..."
            );

          } else {
            this.showSnackbar("#red", res.data.status.message);
          }
        })
        .catch((error) => {
          window.console.log("error:" + error);
        })
        .finally(() => (this.fetch_org_load = false));
    },
    reschedule(item) {
      console.log(item);
      this.reschedule_company = "";
      this.reschedule_selected_ay = "";
      this.reschedule_company_id = "";
      this.reschedule_companyoffering_id = "";
      this.reschedule_company = item.company;
      this.reschedule_company_id = item.company_id;
      this.reschedule_companyoffering_id = item.id;
      this.reschedule_selected_ay = item.academicyear_id;
      this.reschedule_dialog = true;
      this.getcode_reschedule(this.reschedule_company_id, this.reschedule_selected_ay);
    },
    save_new_company_scheduling() {
      var params = {
        id: this.new_offer_comapny_id,
        company: this.new_selected_company,
        placementtype: this.new_selected_placement_type,
        academicyear: this.new_selected_ay,
        companytype: this.new_selected_company_type,
        companylevel: this.new_selected_company_level,
        department: this.new_selected_department,
        internshiptype: this.new_selected_intership_type,
        company_code: this.new_selected_company_code
      };
      axios
        .post("/TPOCompanyScheduling/save_new_company_scheduling", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.fetch_Company_Scheduling_new();
            this.showSnackbar("#4caf50", "New Company Offered  Successfully...");
            this.offernew_dialog = false;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("red", "Offered Company Not Updated...");
          console.log(error);
        });
    },
    offer_new_company() {
      this.offernew_dialog = true;
      this.offernewloading = true;

      axios.post("/TPOCompanyScheduling/geteditComapnyScheduling").then((res) => {
        if (res.data.msg == "200") {
          this.offernewloading = false;
          this.department_list = res.data.department_list;
          this.company_level_list = res.data.company_level_list;
          this.company_type_list = res.data.company_type_list;
          this.internship_type_list = res.data.internship_type_list;
        } else {
          this.offernewloading = false;
          this.offernew_dialog = false;
          this.showSnackbar("red", res.data.msg);
        }
      });
    },
    companyoncampus(item) {
      localStorage.setItem("companyname", item.company);
      localStorage.setItem("scheduling_id", item.id);
      let routeData = this.$router.resolve({
        name: "tpo-company-campus-process",
        query: { scheduling_id: item.id, cmp_name: item.company },
      });
      window.open(routeData.href, "_blank");
    },
    addquestions(item) {
      localStorage.setItem("code", item.company_code);
      localStorage.setItem("ay", item.academicyear);
      localStorage.setItem("companyname", item.company);
      localStorage.setItem("scheduling_id", item.id);
      let routerData = this.$router.resolve({
        name: "admin-master-offeringquestion",
        query: {
          scheduling_id: item.id,
          cmp_name: item.company,
          code: item.company_code,
          ay: item.academicyear,
        },
      });
      window.open(routerData.href, "_blank");
    },
    deleteItem(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/TPOCompanyScheduling/deleteCompanyScheduling", data)
        .then((res) => {
          //console.log("inside axios  deleteItem")
          if (res.data.code == "SUCCESS") {
            this.showSnackbar("#4caf50", "Company Offering Deleted Successfully...");
            this.fetch_Company_Scheduling_new();
          } else if (res.data.code == "failed") {
            this.showSnackbar("#4caf50", res.data.message);
          } else {
            //error
            this.showSnackbar("#b71c1c", "Could Not Delete Company offering");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },

    edit_company_scheduling() {
      var params = {
        id: this.edit_offer_comapny_id,
        company: this.edit_selected_company,
        placementtype: this.edit_selected_placement_type,
        academicyear: this.edit_selected_ay,
        companytype: this.edit_selected_company_type,
        companylevel: this.edit_selected_company_level,
        department: this.edit_selected_department,
        internshiptype: this.edit_selected_intership_type,
      };
      axios
        .post("/TPOCompanyScheduling/editsaveComapnyScheduling", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.fetch_Company_Scheduling_new();
            this.showSnackbar("#4caf50", "Offered Company Updated Successfully...");
            this.dialog = false;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("red", "Offered Company Not Updated...");
          console.log(error);
        });
    },
    geteditComapnyScheduling(id) {
      this.dialog = true;
      this.editloading = true;

      axios.post("/TPOCompanyScheduling/geteditComapnyScheduling").then((res) => {
        if (res.data.msg == "200") {
          this.editloading = false;
          this.department_list = res.data.department_list;
          this.company_level_list = res.data.company_level_list;
          this.company_type_list = res.data.company_type_list;
          this.internship_type_list = res.data.internship_type_list;
          var item = this.schedule_company_list.find((item) => item.id == id);
          this.edit_selected_company_level = res.data.edit_selected_company_level;
          this.edit_selected_ay = item.academicyear_id;
          this.edit_selected_company = item.company_id;
          this.edit_selected_company_code = item.company_code;
          this.edit_selected_placement_type = item.placementtype_id;
          this.findinternshiptype();
          this.edit_selected_intership_type = item.internshiptype_id;
          this.edit_selected_company_type = item.companytype_id;
          this.edit_offer_comapny_id = id;
        } else {
          this.editloading = false;
          this.dialog = false;
          this.showSnackbar("red", res.data.msg);
        }
      });
    },
    close() {
      this.dialog = false;
      this.edit_selected_department = "";
      this.edit_selected_company_level = "";
      this.edit_selected_company_type = "";
      this.edit_selected_intership_type = "";
      this.edit_selected_placement_type = "";
      this.edit_selected_company_code = "";
      this.edit_selected_ay = "";
      this.edit_selected_company = "";
      this.display_internship = false;
    },
    close1() {
      this.offernew_dialog = false;
      this.new_selected_department = "";
      this.new_selected_company_level = "";
      this.new_selected_company_type = "";
      this.new_selected_intership_type = "";
      this.new_selected_placement_type = "";
      this.new_selected_company_code = "";
      this.new_selected_ay = "";
      this.new_selected_company = "";
      this.new_display_internship = false;
    },
    findinternshiptype() {
      this.display_internship = false;
      var item = this.placement_type_list.find(
        (item) => item.id == this.edit_selected_placement_type
      );
      if (item.isInternship) this.display_internship = true;
    },
    findinternshiptype1() {
      this.new_display_internship = false;
      var item = this.placement_type_list.find(
        (item) => item.id == this.new_selected_placement_type
      );
      if (item.isInternship) this.new_display_internship = true;
    },
    getcode(company, ay) {
      if (company && ay) {
        var params = { company_id: company, ay: ay };
        axios.post("/TPOCompanyScheduling/getcode1", params).then((res) => {
          if (res.data.msg == "200") {
            this.edit_selected_company_code = res.data.code;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });
      }
    },
    getcode_reschedule(company, ay) {
      if (company && ay) {
        var params = { company_id: company, ay: ay };
        axios.post("/TPOCompanyScheduling/getcode1", params).then((res) => {
          if (res.data.msg == "200") {
            this.reschedule_company_code = res.data.code;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });
      }
    },
    getcode_new(company, ay) {
      if (company && ay) {
        var params = { company_id: company, ay: ay };
        axios.post("/TPOCompanyScheduling/getcode1", params).then((res) => {
          if (res.data.msg == "200") {
            this.new_selected_company_code = res.data.code;
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        });
      }
    },
    activate(id) {
      var params = { id: id };
      axios
        .post("/TPOCompanyScheduling/activateComapnyScheduling", params)
        .then((res) => {
          if (res.data.status == "200") {
            this.showSnackbar("green", res.data.message); // show snackbar
          }
        });
    },
    init() {
      axios.post("/TPOCompanyScheduling/TPOCompanyScheduling_new").then((res) => {
        if (res.data.msg == "200") {
          this.placement_type_list = res.data.placement_type_list;
          this.ay_list = res.data.ay_list;
          this.company_list = res.data.company_list;
          this.selected_placement_type = "ALL";
          this.selected_ay = res.data.currentay;
        }
      });
    },
    fetch_Company_Scheduling_new() {
      this.loading = true;
      var params = {
        ay: this.selected_ay,
        company_name: this.selected_company_type,
        placement_type: this.selected_placement_type,
      };

      axios
        .post("/TPOCompanyScheduling/fetch_Company_Scheduling_new", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.schedule_company_list = res.data.schedule_company_list;
            this.loading = false;
          }
        });
    },
    setcompany_valiable(value) {
      this.selected_company_type = value;
    },
    setvariable() {
      this.selected_company_type = this.selected_company.name;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
